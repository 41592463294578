<template>
    <div class="h-100 flex-display flex-column flex-center">
        <div>
            <img src="/img/logo_img.svg" alt="myonline therapy"/>
        </div>
        <h1 class="form-heading mt-5">Forgot Password</h1>
        <p class="app-error mt-2">
            {{error}}
        </p>
        <div class="w-343 mt-3" id="form">
            <div class="form-group">
                <label class="form-label">Email</label>
                <input class="input-text" v-model="email" placeholder="e.g., jondoe@example.com">
            </div>
            <button class="btn" :disabled="disable" @click="requestResetPasswordLink">Send Reset Password Link</button>
            <div class="text-center mt-2">
                <router-link to="/login">Return To Sign in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ForgotPassword",
        data(){
            return{
                email: "",
                disable: true,
                error:""
            }
        },
        methods:{
            requestResetPasswordLink(){
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+"password/send-reset-link", {
                    email: this.email,
                }).then(response => {
                    loader.hide();
                    self.$toastr("success","We have sent an email to "+self.email+" with instructions on how to reset your password.","")
                    self.$router.push("/login");
                }).catch(function (error) {
                    loader.hide();
                    self.error = error.response.data.message;
                });
            },
            enableButton(){
                this.disable = !(this.email !=="")
            }
        },
        watch:{
            email: function () {
                this.enableButton();
            }
        }
    }
</script>

<style scoped>

</style>
